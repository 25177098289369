<template>
  <table>
    <thead>
    <tr>
      <th class="center-align" style="width: 100px">ID</th>
      <th>Название</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="(record, idx) of records" :key="record.id">
      <td class="center-align">
        <router-link :to="{path: '/home_locations/' + record.id}"
                     v-tooltip="'Открыть'"
                     class="btn-small btn blue darken-4">
          {{record.id}}
        </router-link>
      </td>
      <td>{{record.name}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    props: {
      records: {
        required: true,
        type: Array
      }
    }
  }
</script>
